import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import moment from "moment"
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const NotFound = React.lazy(() => import("./components/pages/NotFound"));


// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})


function App() {

  const options = {
    autoConfig: true,
    debug: false,
  };


  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleAccept = () => {
    setShow(false)
    const nextMonth = moment().add(1, 'months')
    document.cookie = `hasAccepted=true; expires=${nextMonth}`;
    ReactPixel.grantConsent()
  }
  useEffect(() => {
    if (!window.location.href.includes('localhost') && !window.location.href.includes("friendly-tulumba") && !window.location.href.includes("netlify")) {
      ReactPixel.init('726717368906352', {}, options);
      ReactPixel.revokeConsent();
      ReactPixel.pageView()
    }
    const hasAcceptedCookie = document.cookie
      .split(";")
      .find((cookie) => cookie.includes("hasAccepted=true"))

    if (hasAcceptedCookie) {
      setShow(false);
      ReactPixel.grantConsent()
    }
    else {
      setShow(true)
    }
  }, [])

  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />

          <div className='w-100 d-flex align-items-center justify-content-center'>

            <Modal centered show={show}>
              <Modal.Header closeButton>
                <Modal.Title>Cookies policy</Modal.Title>
              </Modal.Header>
              <Modal.Body>We collect cookies to analyze our website traffic and performance; we never collect any personal data.</Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleAccept}>
                  Accept cookies
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Switch>
            <Route path="/" exact component={Hometwo} />
            {/* <Route path="/home-v2" exact component={Hometwo} />
          <Route path="/home-v3" exact component={Homethree} /> */}
            {/* <Route path="/room-list" exact component={Roomlist} /> */}
            {/* <Route path="/room-grid" exact component={Roomgrid} />
          <Route path="/room-details/:id" exact component={Roomdetails} /> */}
            {/* <Route path="/blog" exact component={Blog} />
          <Route path="/blog/cat/:catId" exact component={props => (<Blog {...props} key={window.location.pathname} />)} />
          <Route path="/blog/tag/:tagId" exact component={props => (<Blog {...props} key={window.location.pathname} />)} />
          <Route path="/blog/user/:userId" exact component={props => (<Blog {...props} key={window.location.pathname} />)} />
          <Route path="/blog-standard" exact component={Blogstandard} />
          <Route path="/blog-details/:id" exact component={Blogdetails} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/about" exact component={About} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/restaurant" exact component={Restaurant} />
          <Route path="/places" exact component={Places} />
          <Route path="/places-details/:id" exact component={Placesdetails} />
          <Route path="/offers" exact component={Offers} />
          <Route path="/menu" exact component={Menu} />  */}
            <Route exact component={NotFound} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
